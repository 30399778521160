import React, { Fragment } from 'react'
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'

import moment from 'moment'
import { getTracking } from 'API/user'

import 'styles/index.scss'
import { RepoMap } from 'components/RepoMap'
import AddEvent from 'components/AddEvents'

import FilterEvents from 'components/FilterEvents'
const EventOptions = [
	{
		id: 1,
		name: 'chkLecture',
		value: 'Lecture',
		label: 'Lecture',
		class: 'bg-light-primary',
		color: '#2c128a',
		theme: 'primary',
		isChecked: true,
	},
	{
		id: 2,
		name: 'chkQuiz',
		value: 'Quiz',
		label: 'Quiz',
		class: 'bg-light-dark',
		color: '#100231',
		theme: 'dark',
		isChecked: true,
	},
	{
		id: 3,
		name: 'chkAssignment',
		value: 'Assignment',
		label: 'Assignment',
		class: 'bg-light-warning',
		color: '#ff8f00',
		theme: 'warning',
		isChecked: true,
	},
	// {
	// 	id: 4,
	// 	name: "chkTest",
	// 	value: "Test",
	// 	label: "Test",
	// 	class: "bg-light-danger",
	// 	color: "#e42728",
	// 	theme: "danger",
	// 	isChecked: true,
	// },
	// {
	// 	id: 5,
	// 	name: "chkHoliday",
	// 	value: "Holiday",
	// 	label: "Holiday",
	// 	class: "bg-light-success",
	// 	color: "#28b463",
	// 	theme: "success",
	// 	isChecked: true,
	// },
	{
		id: 6,
		name: 'chkOther',
		value: 'Other',
		label: 'Other',
		class: 'bg-light-info',
		color: '#4fc3f7',
		theme: 'info',
		isChecked: true,
	},
]

export enum RepoColorMap {
	ResourceRepository = 'bg-light-info',
	AssignmentRepository = 'bg-light-dark',
	QuizRepository = 'bg-light-success',
	ExternalLinkRepository = 'bg-dark-danger',
	LectureRepository = 'bg-light-warning',
}

const calendar = () => {
	React.useEffect(() => {
		const run = async () => {
			const tracking = await getTracking(false)
			if (tracking) {
				setEvents(
					tracking.map((elem) => ({
						id: elem.uid,
						title: (RepoMap as any)[elem.__t] + '-' + elem.name,
						start: moment(new Date(elem.available_from)).format('YYYY-MM-DD HH:mm:ss'),
						end: moment(new Date(elem.available_till)).format('YYYY-MM-DD HH:mm:ss'),
						// allDay: true,
						classNames: [(RepoColorMap as any)[elem.__t] ?? 'bg-light-primary'],
						label: (RepoMap as any)[elem.__t],
					}))
				)
			}
		}
		run()
	}, [])
	const [events, setEvents] = React.useState([
		{
			id: '1',
			title: 'All-day Event 1',
			start: moment('2021-06-07T12:00:00').format('YYYY-MM-DD HH:mm:ss'),
			end: moment('2021-06-06T12:30:00').format('YYYY-MM-DD HH:mm:ss'),
			allDay: true,
			classNames: ['bg-light-primary'],
			label: 'Lecture',
		},
		{
			id: '2',
			title: 'Timed event',
			start: moment('2021-06-05T10:00:00').format('YYYY-MM-DD HH:mm:ss'),
			end: moment('2021-06-05T12:30:00').format('YYYY-MM-DD HH:mm:ss'),
			classNames: ['bg-light-dark'],
			label: 'Quiz',
		},
		{
			id: '3',
			title: 'My Event',
			start: moment('2021-06-10T13:00:00').format('YYYY-MM-DD HH:mm:ss'),
			end: moment('2021-06-10T14:30:00').format('YYYY-MM-DD HH:mm:ss'),
			classNames: ['bg-light-success'],
			label: 'Holiday',
		},
		{
			id: '4',
			title: 'Timed event 2',
			start: moment('2021-06-11T15:00:00').format('YYYY-MM-DD HH:mm:ss'),
			end: moment('2021-06-11T15:30:00').format('YYYY-MM-DD HH:mm:ss'),
			classNames: ['bg-light-danger'],
			label: 'Test',
		},
		{
			id: '5',
			title: 'Timed event 3',
			start: moment('2021-05-13T15:30:00').format('YYYY-MM-DD HH:mm:ss'),
			end: moment('2021-06-13T17:00:00').format('YYYY-MM-DD HH:mm:ss'),
			classNames: ['bg-light-warning'],
			allDay: true,
			label: 'Assignment',
		},
		{
			id: '6',
			title: 'Timed event 3',
			start: moment('2021-06-15T16:00:00').format('YYYY-MM-DD HH:mm:ss'),
			end: moment('2021-06-15T16:30:00').format('YYYY-MM-DD HH:mm:ss'),
			classNames: ['bg-light-info'],
			label: 'Other',
		},
		{
			id: '7',
			title: 'Timed event 3',
			start: moment('2021-06-15T10:00:00').format('YYYY-MM-DD HH:mm:ss'),
			end: moment('2021-06-07T12:30:00').format('YYYY-MM-DD HH:mm:ss'),
			classNames: ['bg-light-primary'],
			label: 'Lecture',
		},
		{
			id: '8',
			allDay: true,
			classNames: ['bg-light-danger'],
			course: '',
			description: '',
			end: '2021-06-09 12:00',
			eventURL: '',
			label: 'Test',
			location: '',
			start: '2021-06-06 12:00',
			title: 'Mid-sem Exams',
			typeOfEvent: '',
		},
	])

	const [show, setShow] = React.useState(false)
	return (
		<Fragment>
			<div className='container mt-4 mb-4'>
				<AddEvent
					show={show}
					handleClose={() => setShow(false)}
					mode={'add'}
					addEvent={() => {
						return
					}}
					editEvent={() => {
						return
					}}
				></AddEvent>
				<div className='app-content content overflow-hidden'>
					<div className='content-overlay'></div>
					<div className='header-navbar-shadow'></div>
					<div className='content-wrapper animate__animated animate__fadeIn'>
						<div className='app-calendar overflow-hidden border'>
							<div className='no-gutters row'>
								<FilterEvents
									isFilterShow={false}
									addEvent={() => {
										return
									}}
									filterEvents={() => {
										return
									}}
									types={[] ?? EventOptions}
									setUpdateMode={() => {
										return
									}}
								/>
								<div className='position-relative col'>
									<div className='shadow-none border-0 mb-0 rounded-0 card'>
										<div className='pb-0 card-body'>
											<FullCalendar
												plugins={[dayGridPlugin, timeGridPlugin, listPlugin, interactionPlugin]}
												headerToolbar={{
													start: 'sidebarToggle,prev,next',
													center: 'title',
													end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
												}}
												// validRange={{start: new Date().toISOString().replace(/T.*$/, "")}}
												dateClick={(e: any) => {
													// alert('add')
													// eventUpdateMode('add')
													// handleShow(e.date)
												}}
												eventClick={(e: any) => {
													//console.log(e);
													// eventUpdateMode('edit')
													// setEditData(e.event)
													// if (e.event.end !== null) {
													// 	handleShow(e.event.end)
													// } else {
													// 	handleShow(e.event.start)
													// }
												}}
												editable={true}
												selectable={true}
												dayMaxEvents={true}
												initialView='dayGridMonth'
												events={events}
												themeSystem='bootstrap'
												customButtons={{
													sidebarToggle: {
														text: '',
														// click: showFilterPanel,
														click: () => {
															return
														},
													},
												}}
											/>
										</div>
									</div>
								</div>
								{/* <div className={`body-content-overlay ${isFilterShow ? 'show' : ''}`} onClick={hideFilterPanel}></div> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default calendar
