import React, { Fragment, useState } from 'react'
const Image = '/CalendarEvents.svg'
function FilterEvents({ types, isFilterShow, addEvent: _addEvent, filterEvents, setUpdateMode: _setUpdateMode }: any) {
	const [eventTypes, setEventTypes] = useState({
		allChecked: true,
		list: types,
	})
	const handleChange = (
		e: {
			target: {
				id: any
				checked: any
			}
		},
		type?: string
	) => {
		//console.log(e);
		const itemName = e.target.id
		const checked = e.target.checked
		setEventTypes((prevState) => {
			let { list, allChecked } = prevState
			if (itemName === 'chkViewAll') {
				allChecked = checked
				list = list.map((item: any) => ({ ...item, isChecked: checked }))
				allChecked ? filterEvents('all', true) : filterEvents('all', false)
			} else {
				list = list.map((item: any) => (item.name === itemName ? { ...item, isChecked: checked } : item))
				checked ? filterEvents(type, true) : filterEvents(type, false)
				allChecked = list.every((item: any) => item.isChecked)
			}
			return { list, allChecked }
		})
	}
	return (
		<Fragment>
			<div
				id='app-calendar-sidebar'
				className={`col app-calendar-sidebar ${isFilterShow ? 'show' : ''} flex-grow-0 overflow-hidden d-flex flex-column col`}
			>
				<div className='sidebar-wrapper'>
					<div className='card-body d-flex justify-content-center my-sm-0 mb-3 card-body'>
						{/* <button
							type='button'
							className='btn btn-primary btn-block '
							onClick={() => {
								setUpdateMode('add')
								addEvent()
							}}
						>
							<span className='align-middle'>Add Event</span>
						</button> */}
					</div>
					<div className='card-body'>
						<h5 className='section-label mb-1'>
							<span className='align-middle'>Filter</span>
						</h5>
						<div className='mb-1 custom-checkbox custom-control' id='first'>
							<input
								type='checkbox'
								id='chkViewAll'
								className='custom-control-input'
								checked={eventTypes.allChecked}
								onChange={(e) => {
									handleChange(e)
								}}
							/>
							<label className='custom-control-label' htmlFor='chkViewAll'>
								View All
							</label>
						</div>
						<div className='calendar-events-filter'>
							{eventTypes.list.map((type: any) => (
								<div key={type.id} className={`custom-control-${type.theme} mb-1 custom-checkbox custom-control`}>
									<input
										type='checkbox'
										id={`chk${type.value}`}
										className='custom-control-input'
										checked={type.isChecked}
										onChange={(e) => {
											handleChange(e, type.value)
										}}
									/>
									<label className='custom-control-label' htmlFor={`chk${type.value}`}>
										{type.label}
									</label>
								</div>
							))}
						</div>
					</div>
				</div>
				<div className='mt-auto'>
					<img className='img-fluid' src={Image} alt='illustration' />
				</div>
			</div>
		</Fragment>
	)
}
export default FilterEvents
