import React, { Fragment, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
// import DatePicker from './DatePicker'
import Select from 'react-select'
import chroma from 'chroma-js'
import moment from 'moment'

import Flatpickr from 'react-flatpickr'
import rangePlugin from 'flatpickr/dist/plugins/rangePlugin'

export function DatePicker(props: any) {
	const options = props.options
		? Object.assign({}, props.options, {
				plugins: [new (rangePlugin as any)({ input: '#' + props.fromDateID })],
		  })
		: {
				plugins: [new (rangePlugin as any)({ input: '#' + props.fromDateID })],
		  }
	const disabled = props.disabled ? props.disabled : false
	const placeholder = props.placeholder ? props.placeholder : ''

	const setDate = (newValue: any) => {
		console.log(newValue)
		if (props.onChange) {
			props.onChange(newValue)
		}
	}
	return <Flatpickr className='form-control clickable' disabled={disabled} placeholder={placeholder} options={options} onChange={setDate} />
}

const EventOptions = [
	{
		value: 'Lecture',
		label: 'Lecture',
		class: 'bg-light-primary',
		color: '#2c128a',
		theme: 'primary',
	},
	{
		value: 'Quiz',
		label: 'Quiz',
		class: 'bg-light-dark',
		color: '#100231',
		theme: 'dark',
	},
	{
		value: 'Assignment',
		label: 'Assignment',
		class: 'bg-light-warning',
		color: '#ff8f00',
		theme: 'warning',
	},
	{
		value: 'Test',
		label: 'Test',
		class: 'bg-light-danger',
		color: '#e42728',
		theme: 'danger',
	},
	{
		value: 'Holiday',
		label: 'Holiday',
		class: 'bg-light-success',
		color: '#28b463',
		theme: 'success',
	},
	{
		value: 'Other',
		label: 'Other',
		class: 'bg-light-info',
		color: '#4fc3f7',
		theme: 'info',
	},
]
const dot = (color = '#ccc') => ({
	alignItems: 'center',
	display: 'flex',

	':before': {
		backgroundColor: color,
		borderRadius: 10,
		content: '" "',
		display: 'block',
		marginRight: 8,
		height: 10,
		width: 10,
	},
})
const colourStyles = {
	control: (styles: CSSStyleSheet) => ({ ...styles, backgroundColor: 'white' }),
	option: (styles: any, { data, isDisabled, isFocused, isSelected }: any) => {
		const color = chroma(data.color)
		return {
			...styles,
			backgroundColor: isDisabled ? null : isSelected ? color.alpha(0.1).css() : isFocused ? color.alpha(0.1).css() : null,
			color: isDisabled ? '#ccc' : isSelected ? data.color : data.color,
			cursor: isDisabled ? 'not-allowed' : 'default',

			':active': {
				...styles[':active'],
				backgroundColor: !isDisabled && (isSelected ? data.color : color.alpha(0.3).css()),
			},
			...dot(data.color),
		}
	},
	input: (styles: any) => ({ ...styles, ...dot() }),
	placeholder: (styles: any) => ({ ...styles, ...dot() }),
	singleValue: (styles: any, { data }: any) => ({ ...styles, ...dot(data.color) }),
}
const courseOptions = [
	{
		value: 'Blockchain Technology',
		label: 'Blockchain Technology',
	},
	{
		value: 'Computer Graphics',
		label: 'Computer Graphics',
	},
	{
		value: 'Data Structures',
		label: 'Data Structures',
	},
	{
		value: 'Fullstack Development',
		label: 'Fullstack Development',
	},
]
const typeOptions = [
	{
		value: 'Courses',
		label: 'Courses',
	},
	{
		value: 'Lectures',
		label: 'Lectures',
	},
	{
		value: 'Assignments',
		label: 'Assignments',
	},
	{
		value: 'Holiday',
		label: 'Holiday',
	},
]
const AddEvent: React.FC<{
	mode: 'add' | 'edit'
	data?: any
	show: boolean
	handleClose: CallableFunction
	addEvent: CallableFunction
	editEvent: CallableFunction
}> = ({ mode, data, show, handleClose, addEvent, editEvent }) => {
	const [eventData, setEventData] = useState({
		id: undefined,
		title: '',
		start: '',
		end: '',
		classNames: [''],
		allDay: false,
		extendedProps: {
			label: '',
			typeOfEvent: '',
			course: '',
			eventURL: '',
			location: '',
			description: '',
		},
	})
	const updateDate = (date1: moment.MomentInput, date2: moment.MomentInput) => {
		//console.log(date1);
		//console.log(date2);
		// if (!(date2 === undefined)) {
		setEventData({
			...eventData,
			start: moment(date1).format('YYYY-MM-DD HH:mm'),
			end: moment(date2).format('YYYY-MM-DD HH:mm'),
		})
		// }
	}
	// const getLabel = (labelName) => {
	// 	if (data.extendedProps !== undefined) {
	// 		return EventOptions.filter((option) => {
	// 			return data.extendedProps.label === labelName;
	// 		})[0];
	// 	}
	// };
	return (
		<Fragment>
			<Modal show={show} onHide={handleClose} animation={false}>
				<Modal.Header closeButton>
					<Modal.Title>
						{mode === 'add' ? 'Add' : 'Edit'} Event
						{/* <Button
							className="btn btn-danger"
							onClick={() => {
								console.log(mode);
								console.log(eventData);
							}}
						/> */}
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form>
						<Form.Group>
							<Form.Label>Title</Form.Label>
							<Form.Control
								type='text'
								placeholder='Title'
								defaultValue={data ? data.title : ''}
								onChange={(e) => {
									setEventData({
										...eventData,
										title: e.target.value,
									})
								}}
							/>
						</Form.Group>
						<div className='row'>
							<div className='col-6'>
								<Form.Group>
									<Form.Label>
										Start Date
										{/* Start Date: {eventData.start} */}
									</Form.Label>
									<DatePicker
										options={{
											dateFormat: 'd-M-Y h:i K',
											defaultDate: data ? data.start : '',
											disableMobile: 'true',
											enableTime: true,
										}}
										fromDateID='eventEndDate'
										selectValue={[]}
										placeholder='From Date'
										onChange={(dates: any) => {
											updateDate(dates[0], dates[1])
										}}
									/>
								</Form.Group>
							</div>
							<div className='col-6'>
								<Form.Group>
									<Form.Label>
										End Date
										{/* End Date:{eventData.end} */}
									</Form.Label>
									<Form.Control id='eventEndDate' placeholder='To Date' />
								</Form.Group>
							</div>
						</div>

						<div className='row'>
							<div className='col-6'>
								<Form.Group>
									<Form.Label>Label</Form.Label>
									<Select
										options={EventOptions}
										styles={colourStyles as any}
										defaultValue={
											(data
												? data.extendedProps
													? EventOptions.filter((option) => {
															return data.extendedProps.label === option.label
													  })[0]
													: ''
												: '') as any
										}
										onChange={(e) => {
											setEventData({
												...eventData,
												classNames: [e.class],
												extendedProps: {
													...eventData.extendedProps,
													label: e.value,
												},
											})
										}}
									></Select>
								</Form.Group>
							</div>
							<div className='col-6'>
								<Form.Group>
									<Form.Label>All Day</Form.Label>
									<Form.Check
										type='switch'
										className='custom-switch custom-switch-md align-items-center'
										id='custom-switch'
										label='All Day'
										defaultChecked={data ? data.allDay : false}
										onChange={(e) => {
											setEventData({
												...eventData,
												allDay: e.target.checked,
											})
										}}
									></Form.Check>
								</Form.Group>
							</div>
						</div>
						<div className='row'>
							<div className='col-6'>
								<Form.Group>
									<Form.Label>Type Of Event</Form.Label>
									<Select
										options={typeOptions}
										defaultValue={
											(data
												? data.extendedProps
													? typeOptions.filter((type) => {
															return type.value === data.extendedProps.typeOfEvent
													  })[0]
													: ''
												: '') as any
										}
										onChange={(e) => {
											setEventData({
												...eventData,
												extendedProps: {
													...eventData.extendedProps,
													typeOfEvent: e.value,
												},
											})
										}}
									></Select>
								</Form.Group>
							</div>
							<div className='col-6'>
								<Form.Group>
									<Form.Label>Courses</Form.Label>
									<Select
										options={courseOptions}
										defaultValue={
											(data
												? data.extendedProps
													? courseOptions.filter((course) => {
															return course.value === data.extendedProps.course
													  })[0]
													: ''
												: '') as any
										}
										onChange={(e) => {
											setEventData({
												...eventData,
												extendedProps: {
													...eventData.extendedProps,
													course: e.value as any,
												},
											})
										}}
									></Select>
								</Form.Group>
							</div>
						</div>
						<Form.Group>
							<Form.Label>Event URL</Form.Label>
							<Form.Control
								type='text'
								placeholder='www.example.com'
								defaultValue={data && data.extendedProps && data.extendedProps.eventURL ? data.extendedProps.eventURL : ''}
								onChange={(e) => {
									setEventData({
										...eventData,
										extendedProps: {
											...eventData.extendedProps,
											eventURL: e.target.value,
										},
									})
								}}
							/>
						</Form.Group>
						<Form.Group>
							<Form.Label>Location</Form.Label>
							<Form.Control
								type='text'
								placeholder='Office'
								defaultValue={data && data.extendedProps && data.extendedProps.location ? data.extendedProps.location : ''}
								onChange={(e) => {
									setEventData({
										...eventData,
										extendedProps: {
											...eventData.extendedProps,
											location: e.target.value,
										},
									})
								}}
							/>
						</Form.Group>
						<Form.Group controlId='exampleForm.ControlTextarea1'>
							<Form.Label>Description</Form.Label>
							<Form.Control
								as='textarea'
								rows={3}
								defaultValue={data && data.extendedProps && data.extendedProps.description ? data.extendedProps.description : ''}
								onChange={(e) => {
									setEventData({
										...eventData,
										extendedProps: {
											...eventData.extendedProps,
											description: e.target.value,
										},
									})
								}}
							/>
						</Form.Group>
					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant='outline-primary'
						onClick={() => {
							setEventData({
								id: undefined,
								title: '',
								start: '',
								end: '',
								classNames: [],
								allDay: false,
								extendedProps: {
									label: '',
									typeOfEvent: '',
									course: '',
									eventURL: '',
									location: '',
									description: '',
								},
							})
							handleClose()
						}}
					>
						Close
					</Button>
					<Button
						variant='primary'
						onClick={() => {
							if (mode === 'add') {
								addEvent(eventData)
							} else {
								editEvent(eventData)
							}
							setEventData({
								id: undefined,
								title: '',
								start: '',
								end: '',
								classNames: [],
								allDay: false,
								extendedProps: {
									label: '',
									typeOfEvent: '',
									course: '',
									eventURL: '',
									location: '',
									description: '',
								},
							})
						}}
					>
						Save Changes
					</Button>
				</Modal.Footer>
			</Modal>
		</Fragment>
	)
}

export default AddEvent
